




































































































































































import router from "@/router";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";

import VuePhoneNumberInput from "vue-phone-number-input";
import { getPhoneInputOptions } from "@/helpers/phone-input";
import { isValidIdentityInfo, isValidPassword } from "@/helpers/index";
import { GqlInsertUserInput } from "@/helpers/gql-schema";
import store from "@/store";

export default defineComponent({
  name: "SignUp",
  props: {
    referrerCode: {
      required: false,
      type: String
    }
  },
  components: {
    VuePhoneNumberInput
  },
  setup(props, context) {
    const phoneInput = ref(null);
    const state = reactive({
      isLoading: false,
      signinInfo: {
        name: "",
        firstName: "",
        contact: "",
        password: "",
        passwordConfirmation: "",
        reffererCode: ""
      },
      isPhoneNumberValid: false,
      canSignin: computed((): boolean => {
        return (
          state.isPhoneNumberValid &&
          isValidIdentityInfo(state.signinInfo.name) &&
          isValidIdentityInfo(state.signinInfo.firstName) &&
          isValidPassword(state.signinInfo.password) &&
          state.signinInfo.password === state.signinInfo.passwordConfirmation
        );
      }),
      isValidName: computed((): boolean =>
        isValidIdentityInfo(state.signinInfo.name)
      ),
      isValidFirstName: computed((): boolean =>
        isValidIdentityInfo(state.signinInfo.firstName)
      ),
      isPasswordValid: computed((): boolean =>
        isValidPassword(state.signinInfo.password)
      ),
      isPasswordConfirmationValid: computed(
        (): boolean =>
          isValidPassword(state.signinInfo.passwordConfirmation) &&
          state.signinInfo.password === state.signinInfo.passwordConfirmation
      ),
      referrerInfo: undefined as string | undefined
    });

    onMounted(async () => {
      if (props.referrerCode !== undefined) {
        state.signinInfo.reffererCode = props.referrerCode;
        const referrerInfo = await store.dispatch.users.retrieveReferrerInfo(
          props.referrerCode
        );

        if (referrerInfo)
          state.referrerInfo = referrerInfo.name + " " + referrerInfo.firstName;
      }
    });

    async function signUp() {
      state.isLoading = true;
      const insertUserPayload: GqlInsertUserInput = {
        name: state.signinInfo.name,
        firstName: state.signinInfo.firstName,
        telephone:
          (phoneInput.value as any).phoneFormatted ?? state.signinInfo.contact,
        password: state.signinInfo.password,
        referrerCode: state.signinInfo.reffererCode
      };

      const insertResult = await store.dispatch.users.signup(insertUserPayload);

      if (insertResult.success) {
        await store.dispatch.users.getCurrentUserInfo();
        router.push("/pages/ajouter-service");
      } else {
        context.root.$notify({
          group: "notifs",
          type: "error",
          title: "Oupss",
          text: insertResult.message
        });
      }
      state.isLoading = false;
    }

    const phoneInputOptions = getPhoneInputOptions();

    function onPhoneInputUpdate(eventParams: any) {
      state.isPhoneNumberValid = eventParams.isValid;
    }

    return {
      signUp,
      props,
      phoneInputOptions,
      state,
      onPhoneInputUpdate,
      phoneInput
    };
  }
});
